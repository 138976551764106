import {Route} from 'react-router-dom';
import {PricingPage} from './pricing-table/pricing-page';
import {HiddenPricingPage} from './pricing-table/hidden-pricing-page';
import React, {Fragment} from 'react';
import {FullPageLoader} from '../ui/progress/full-page-loader';
import {
  HiddenPricingPageWrapper1,
  HiddenPricingPageWrapper2,
  HiddenPricingPageWrapper3,
  HiddenPricingPageWrapper4,
  HiddenPricingPageWrapper5
} from './pricing-table/hidden-pricing-page-wrappers';
import { useAuth } from '../auth/use-auth';
import {AccountSettingsPage} from '../auth/ui/account-settings/account-settings-page';

const BillingPageRoutes = React.lazy(
  () => import('./billing-page/billing-page-routes')
);

const CheckoutRoutes = React.lazy(() => import('./checkout/checkout-routes'));

const BillingRoutes = () => {
  const { hasPermission } = useAuth();
  const ChildPricing = () => {
    if (hasPermission('family.open') && !hasPermission('family.add')) {
      return <AccountSettingsPage />;
    }
    return <PricingPage />;
  };
  const ChildBilling = () => {
    if (hasPermission('family.open') && !hasPermission('family.add')) {
      return <AccountSettingsPage />;
    }
    return <BillingPageRoutes />;
  };
  const ChildCheckout = () => {
    if (hasPermission('family.open') && !hasPermission('family.add')) {
      return <AccountSettingsPage />;
    }
    return <CheckoutRoutes />;
  };
  const ChildPlan1 = () => {
    if (hasPermission('family.open') && !hasPermission('family.add')) {
      return <AccountSettingsPage />;
    }
    return <HiddenPricingPageWrapper1 />;
  };
  const ChildPlan2 = () => {
    if (hasPermission('family.open') && !hasPermission('family.add')) {
      return <AccountSettingsPage />;
    }
    return <HiddenPricingPageWrapper2 />;
  };
  const ChildPlan3 = () => {
    if (hasPermission('family.open') && !hasPermission('family.add')) {
      return <AccountSettingsPage />;
    }
    return <HiddenPricingPageWrapper3 />;
  };
  const ChildPlan4 = () => {
    if (hasPermission('family.open') && !hasPermission('family.add')) {
      return <AccountSettingsPage />;
    }
    return <HiddenPricingPageWrapper4 />;
  };
  const ChildPlan5 = () => {
    if (hasPermission('family.open') && !hasPermission('family.add')) {
      return <AccountSettingsPage />;
    }
    return <HiddenPricingPageWrapper5 />;
  };

  return (
  <Fragment>
    <Route path="/pricing" element={ChildPricing()} />
    <Route path="/1-par-1" element={ChildPlan1()} />
    <Route path="/registracija-pasakums" element={ChildPlan2()} />
    <Route path="/menesis-sadarbibai" element={ChildPlan3()} />
    <Route path="/gads-davana" element={ChildPlan4()} />
    <Route path="/1-par-2" element={ChildPlan5()} />
    <Route
      path="checkout/*"
      element={
        <React.Suspense fallback={<FullPageLoader screen />}>
          {ChildCheckout()} 
        </React.Suspense>
      }
    />
    <Route
      path="billing/*"
      element={
        <React.Suspense fallback={<FullPageLoader screen />}>
          {ChildBilling()} 
        </React.Suspense>
      }
    />
  </Fragment>
  );
};

export { BillingRoutes };
