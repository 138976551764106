import {useMutation} from '@tanstack/react-query';
import {UseFormReturn} from 'react-hook-form';
import {User} from '@common/auth/user';
import {BackendResponse} from '@common/http/backend-response/backend-response';
import {toast} from '@common/ui/toast/toast';
import {apiClient, queryClient} from '@common/http/query-client';
import {DatatableDataQueryKey} from '@common/datatable/requests/paginated-resources';
import {onFormQueryError} from '@common/errors/on-form-query-error';
import {message} from '@common/i18n/message';
import {useNavigate} from '@common/utils/hooks/use-navigate';
import {useAuth} from '@common/auth/use-auth'; // Import the useAuth hook

interface Response extends BackendResponse {
  user: User;
}

export interface CreateUserPayload
  extends Omit<Partial<User>, 'email_verified_at'> {
  email_verified_at?: boolean;
  parent_id?: string; // Ensure parent_id is a string
}

export function useCreateUser(form: UseFormReturn<CreateUserPayload>) {
  const navigate = useNavigate();
  const {user: authUser} = useAuth(); // Get the authenticated user

  return useMutation({
    mutationFn: (props: CreateUserPayload) => createUser(props, authUser!.id.toString()), // Pass the authenticated user ID as a string
    onSuccess: () => {
      toast(message('Lietotājs izveidots'));
      queryClient.invalidateQueries({queryKey: DatatableDataQueryKey('users')});
      window.location.href = '/account-settings';
    },
    onError: r => onFormQueryError(r, form),
  });
}

function createUser(payload: CreateUserPayload, parentId: string): Promise<Response> {
  if (payload.roles) {
    payload.roles = payload.roles.map(r => r.id) as any;
  }
  payload.parent_id = parentId; // Set parent_id in the payload
  return apiClient.post('family-users', payload).then(r => r.data);
}
