import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DataTablePage } from '@common/datatable/page/data-table-page';
import { Trans } from '@common/i18n/trans';
import { DeleteSelectedItemsAction } from '@common/datatable/page/delete-selected-items-action';
import { DataTableEmptyStateMessage } from '@common/datatable/page/data-table-emty-state-message';
import teamSvg from '@common/admin/roles/team.svg';
import { DataTableAddItemButton } from '@common/datatable/data-table-add-item-button';
import { userDatatableColumns } from './family-columns';
import { AccountSettingsPanel } from '../account-settings-panel';
import { AccountSettingsId } from '@common/auth/ui/account-settings/account-settings-sidenav';
import { useAuth } from '@common/auth/use-auth';
import axios from 'axios';

export function FamilyAccessPanel() {
  const { hasPermission, getRestrictionValue } = useAuth();
  const [totalCount, setTotalCount] = useState<number | null>(null);
  const restrictionValue = Number(getRestrictionValue('family.add', 'count'));

  useEffect(() => {
    const fetchTotalCount = async () => {
      try {
        const response = await axios.get('/api/v1/family-users', {
          headers: {
            'Accept': 'application/json',
          },
        });
        setTotalCount(response.data.total_count);
      } catch (error) {
        console.error('Failed to fetch total count:', error);
      }
    };
    fetchTotalCount();
  }, []);

  return (
    <AccountSettingsPanel id={AccountSettingsId.FamilyAccess} title={<Trans message="Saistītie profili" />}>
      <DataTablePage
        endpoint="family-users"
        title={<Trans message="Pārvaldīt saistītos profilus" />}
        columns={userDatatableColumns}
        actions={hasPermission('family.add') && (totalCount ?? 0) < restrictionValue && <Actions />}
        queryParams={{
          with: 'subscriptions,bans,lastLogin',
        }}
        selectedActions={hasPermission('family.add') && <DeleteSelectedItemsAction />}
        emptyStateMessage={
          <DataTableEmptyStateMessage
            image={teamSvg}
            title={<Trans message="Saistītie profili vēl nav izveidoti" />}
            filteringTitle={<Trans message="Nav atbilstošu lietotāju" />}
          />
        }
      />
      {hasPermission('family.add') &&
      <div>
        <p>
          <Trans message="Lietotāju skaits:" /> {totalCount}
          <br></br>
          <Trans message="Maksimālais lietotāju skaits:" /> {getRestrictionValue('family.add', 'count')}
        </p>
      </div>
      }
    </AccountSettingsPanel>
  );
}

function Actions() {
  return (
    <Fragment>
      <DataTableAddItemButton elementType={Link} to="new">
        <Trans message="Izveidot lietotāju" />
      </DataTableAddItemButton>
    </Fragment>
  );
}
