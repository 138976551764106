import {RegisterPage} from './ui/register-page';
import {AuthRoute} from './guards/auth-route';
import {AccountSettingsPage} from './ui/account-settings/account-settings-page';
import {CreateChildPage} from './ui/account-settings/family-access-panel/family-create-child';
import {UpdateChildPage} from './ui/account-settings/family-access-panel/family-update-child';
import {GuestRoute} from './guards/guest-route';
import {ForgotPasswordPage} from './ui/forgot-password-page';
import {ResetPasswordPage} from './ui/reset-password-page';
import React, { Fragment, useEffect, useState } from 'react';
import {Route} from 'react-router-dom';
import {LoginPageWrapper} from '@common/auth/ui/login-page-wrapper';
import {PricingPage} from '../billing/pricing-table/pricing-page';
import { useAuth } from './use-auth';
import axios from 'axios';

const AuthRoutes = () => {
  const { hasPermission, getRestrictionValue, isLoggedIn } = useAuth();
  const [totalCount, setTotalCount] = useState<number | null>(null);

  useEffect(() => {
    if (isLoggedIn && hasPermission('family.open')) {
      const fetchTotalCount = async () => {
        try {
          const response = await axios.get('/api/v1/family-users', {
            headers: {
              'Accept': 'application/json',
            },
          });
          setTotalCount(response.data.total_count);
        } catch (error) {
          console.error('Failed to fetch total count:', error);
        }
      };
      fetchTotalCount();
    }
  }, [isLoggedIn, hasPermission]);

  const renderCreateChildPage = () => {
    if (hasPermission('family.add') && (totalCount ?? 0) < Number(getRestrictionValue('family.add', 'count'))) {
      return <CreateChildPage />;
    }
    return <PricingPage />;
  };

  return (
  <Fragment>
    <Route path="/register" element={<RegisterPage />} />
    <Route
      path="/account-settings"
      element={
        <AuthRoute>
          <AccountSettingsPage />
        </AuthRoute>
      }
    />
    <Route
      path="/account-settings/new"
      element={
        <AuthRoute permission="family.add">
          {renderCreateChildPage()}
        </AuthRoute>
      }
    />
    <Route
      path="/account-settings/:userId/edit"
      element={
        <AuthRoute permission="family.add">
          <UpdateChildPage />
        </AuthRoute>
      }
    />
    <Route
      path="login"
      element={
        <GuestRoute>
          <LoginPageWrapper />
        </GuestRoute>
      }
    />
    <Route
      path="/workspace/join/register"
      element={
        <GuestRoute>
          <RegisterPage />
        </GuestRoute>
      }
    />
    <Route
      path="/workspace/join/login"
      element={
        <GuestRoute>
          <LoginPageWrapper />
        </GuestRoute>
      }
    />
    <Route
      path="forgot-password"
      element={
        <GuestRoute>
          <ForgotPasswordPage />
        </GuestRoute>
      }
    />
    <Route
      path="/password/reset/:token"
      element={
        <GuestRoute>
          <ResetPasswordPage />
        </GuestRoute>
      }
    />
  </Fragment>
  );
};

export { AuthRoutes };
