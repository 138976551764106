import React from 'react';
import { ColumnConfig } from '@common/datatable/column-config';
import { User } from '@common/auth/user';
import { Trans } from '@common/i18n/trans';
import { NameWithAvatar } from '@common/datatable/column-templates/name-with-avatar';
import { Link } from 'react-router-dom';
import { FormattedDate } from '@common/i18n/formatted-date';
import { Tooltip } from '@common/ui/tooltip/tooltip';
import { IconButton } from '@common/ui/buttons/icon-button';
import { EditIcon } from '@common/icons/material/Edit';
import { DialogTrigger } from '@common/ui/overlays/dialog/dialog-trigger';
import { ConfirmationDialog } from '@common/ui/overlays/dialog/confirmation-dialog';
import { useImpersonateUser } from '@common/admin/users/requests/use-impersonate-user';
import { LoginIcon } from '@common/icons/material/Login';
import { useAuth } from '@common/auth/use-auth';

const userDatatableColumns: ColumnConfig<User>[] = [
  {
    key: 'name',
    allowsSorting: true,
    sortingKey: 'email',
    width: 'flex-3 min-w-200',
    visibleInMode: 'all',
    header: () => <Trans message="Lietotājs" />,
    body: (user: User) => (
      <NameWithAvatar
        image={user.avatar}
        label={`${user.first_name || 'Vārds'} ${user.last_name || 'Uzvārds'}`}
        description={user.email}
      />
    ),
  },
  {
    key: 'last_login',
    width: 'w-110',
    header: () => <Trans message="Pēdējā aktivitāte" />,
    body: (user: User) =>
      user.last_login ? (
        <time>
          <FormattedDate date={user.last_login.created_at} />
        </time>
      ) : (
        '-'
      ),
  },
  {
    key: 'actions',
    header: () => <Trans message="Darbības" />,
    width: 'w-80 flex-shrink-0',
    hideHeader: true,
    align: 'end',
    visibleInMode: 'all',
    body: (user: User) => <UserActions user={user} />,
  },
];

interface ImpersonateButtonProps {
  user: User;
}

function ImpersonateButton({ user }: ImpersonateButtonProps) {
  const impersonate = useImpersonateUser();
  return (
    <DialogTrigger type="modal">
      <Tooltip label={<Trans message="Pieteikties kā lietotājs" />}>
        <IconButton size="md">
          <LoginIcon />
        </IconButton>
      </Tooltip>
      <ConfirmationDialog
        title={<Trans message="Pieteikties kā“:name“" values={{ name: user.display_name }} />}
        isLoading={impersonate.isPending}
        body={<Trans message="Pieteikties kā šis lietotājs?" />}
        confirm={<Trans message="Jā" />}
        onConfirm={() => {
          impersonate.mutate({ userId: user.id });
        }}
      />
    </DialogTrigger>
  );
}

interface UserActionsProps {
  user: User;
}

function UserActions({ user }: UserActionsProps) {
  const { hasPermission } = useAuth();
  return (
    <div className="text-muted flex justify-end">
      {hasPermission('family.add') && (
        <>
          <Link to={`${user.id}/edit`}>
            <Tooltip label={<Trans message="Rediģēt lietotāju" />}>
              <IconButton size="md">
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Link>
          <ImpersonateButton user={user} />
        </>
      )}
    </div>
  );
}

export { userDatatableColumns };
