import {useForm} from 'react-hook-form';
import React from 'react';
import {FormTextField} from '../../../../ui/forms/input-field/text-field/text-field';
import {CreateUserPayload, useCreateUser} from './requests/create-user';
import {CrupdateChildForm} from './crupdate-child-form';
import {FileUploadProvider} from '../../../../uploads/uploader/file-upload-provider';
import {Trans} from '@common/i18n/trans';
import {FormImageSelector} from '@common/ui/images/image-selector';

export function CreateChildPage() {
  const form = useForm<CreateUserPayload>();
  const createUser = useCreateUser(form);

  const avatarManager = (
    <FileUploadProvider>
      <FormImageSelector
        name="avatar"
        diskPrefix="avatars"
        variant="avatar"
        stretchPreview
        label={<Trans message="Profila attēls" />}
        previewSize="w-90 h-90"
        showRemoveButton
      />
    </FileUploadProvider>
  );

  return (
    <CrupdateChildForm
      onSubmit={newValues => {
        createUser.mutate(newValues);
      }}
      form={form}
      title={<Trans message="Izveidot lietotāju" />}
      isLoading={createUser.isPending}
      avatarManager={avatarManager}
    >
      <FormTextField
        className="mb-30"
        name="email"
        type="email"
        label={<Trans message="E-pasts" />}
      />
      <FormTextField
        className="mb-30"
        name="password"
        type="password"
        label={<Trans message="Parole" />}
      />
    </CrupdateChildForm>
  );
}