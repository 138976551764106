import {FieldValues, SubmitHandler, UseFormReturn} from 'react-hook-form';
import clsx from 'clsx';
import {ReactNode} from 'react';
import {Link} from 'react-router-dom';
import {useValueLists} from '@common/http/value-lists';
import {FormTextField} from '@common/ui/forms/input-field/text-field/text-field';
import {FormSwitch} from '@common/ui/forms/toggle/switch';
import {Trans} from '@common/i18n/trans';
import {CrupdateResourceLayout} from '../../../../admin/crupdate-resource-layout';
import {useSettings} from '@common/core/settings/use-settings';

interface Props<T extends FieldValues> {
  onSubmit: SubmitHandler<T>;
  form: UseFormReturn<T>;
  title: ReactNode;
  subTitle?: ReactNode;
  isLoading: boolean;
  avatarManager: ReactNode;
  resendEmailButton?: ReactNode;
  children?: ReactNode;
}

export function CrupdateChildForm<T extends FieldValues>({
  onSubmit,
  form,
  title,
  subTitle,
  isLoading,
  avatarManager,
  resendEmailButton,
  children,
}: Props<T>) {
  const {require_email_confirmation} = useSettings();

  return (
    <CrupdateResourceLayout
      onSubmit={onSubmit}
      form={form}
      title={title}
      subTitle={subTitle}
      isLoading={isLoading}
    >
      <div className="mb-40 flex items-start gap-40 md:gap-80">
        {avatarManager}
        <div className="flex-auto">
          {children}
          <FormTextField
            className="mb-30"
            name="first_name"
            label={<Trans message="Vārds" />}
          />
          <FormTextField
            className="mb-30"
            name="last_name"
            label={<Trans message="Uzvārds" />}
          />
        </div>
      </div>

      <div className="mb-30 border-b border-t pb-30 pt-30 hidden">
        <FormSwitch
          className={clsx(resendEmailButton && 'mb-30')}
          disabled={!require_email_confirmation}
          name="email_verified_at"
          description={
            <Trans message="Vai e-pasta adrese ir apstiprināta. Lietotājs nevarēs pieteikties, kamēr adrese nav apstiprināta, ja vien apstiprināšana nav atspējota iestatījumu lapā." />
          }
        >
          <Trans message="E-pasts apstiprināts" />
        </FormSwitch>
        {resendEmailButton}
      </div>
    </CrupdateResourceLayout>
  );
}